import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      {/*<div id="preloader-wrap">*/}
      {/*  <div id="loading">*/}
      {/*    <span id="loading-img">Loading...</span>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div id="preloading">*/}
      {/*  <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/traffic-thumb.png" alt=""/>*/}
      {/*</div>*/}


      <div className="off_canvars_overlay">

      </div>
      <div className="offcanvas_menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="offcanvas_menu_wrapper">
                <div className="canvas_close">
                  <a href="javascript:void(0)"><i className="fa fa-times"></i></a>
                </div>
                <div className="offcanvas-brand text-center mb-40">
                  <img src="/moni.png" alt="" />
                </div>
                <div id="menu" className="text-left ">
                  <ul className="offcanvas_main_menu">
                    <li className="menu-item-has-children active">
                      <a href="https://food.monmi.vn/">Đồ Ăn</a>
                    </li>
                    <li className="menu-item-has-children">
                      <a href="https://food.monmi.vn/">Mua Sắm</a>
                    </li>
                    <li className="menu-item-has-children">
                      <a href="https://food.monmi.vn/">Vận Chuyển</a>
                    </li>
                  </ul>
                </div>
                <div className="offcanvas-social">
                  <ul className="text-center">
                    <li><a href="$"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="$"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="$"><i className="fab fa-instagram"></i></a></li>
                    <li><a href="$"><i className="fab fa-dribbble"></i></a></li>
                  </ul>
                </div>
                <div className="footer-widget-info">
                  <ul>
                    <li><a href=""><i className="fa fa-envelope"></i> support@monmi.vn</a></li>
                    <li><a href=""><i className="fa fa-phone"></i> +(84) 249 998 2323</a></li>
                    <li><a href=""><i className="fa fa-map-marker-alt"></i> 251 Trần Quang Khải, Phường Tân Định, Quận 1</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <header className="appie-header-area appie-sticky">
        <div className="container">
          <div className="header-nav-box">
            <div className="row align-items-center">
              <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                <div className="appie-logo-box">
                  <a href="/">
                    {/* eslint-disable-next-line react/style-prop-object */}
                    <img style={{width: "85%"}} src="/moni.png" alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
                <div className="appie-header-main-menu">
                  <ul>
                    <li>
                      <a href="https://food.monmi.vn">Đồ Ăn</a>

                    </li>

                    <li>
                      <a href="https://food.monmi.vn">Mua Sắm </a>

                    </li>

                    <li>
                        <a href="https://food.monmi.vn">Vận Chuyển</a>

                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                <div className="appie-btn-box text-right">
                  <a className="login-btn" href="https://food.monmi.vn"><i className="fa fa-user"></i> Login</a>
                  <a className=" animated_btn ml-30" href="#">Get Started</a>
                  <div className="toggle-btn ml-30 canvas_open d-lg-none d-block">
                    <i className="fa fa-bars"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </header>



      <section className="appie-hero-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="appie-hero-content">
                <h1 className="appie-title">Tận hưởng tất cả, trong hệ thống mới mẻ này.</h1>
                <p>Hội tụ những điều tuyệt vời, quan tâm bạn như người nhà của chúng tôi.</p>
                <ul>
                  <li><a className="animated_btn animated_btn-ios" href="#"><i className="fab fa-apple"></i> Download for iOS</a></li>
                  <li><a className="item-2 animated_btn2" href="#"><i className="fab fa-google-play"></i> Download for Android</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="appie-hero-thumb">
                <div className="thumb">
                  <img style={{width:"36%"}} src="/photo_2022-04-11_21-11-14.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-shape-1">
          <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/shape/shape-2.png" alt="" />
        </div>
        <div className="hero-shape-2">
          <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/shape/shape-3.png" alt="" />
        </div>
        <div className="hero-shape-3">
          <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/shape/shape-4.png" alt="" />
        </div>
      </section>


      <section className="appie-service-area pt-90 pb-100" id="service">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">Tận Hưởng Những Giây Phút Tuyệt Vời </h3>
                <p>Mua Sắm Online Nhanh Chóng Tiện Lợi. </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="appie-single-service text-center mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                <div className="icon">
                  <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/icon/1.png" alt="" />
                    <span>1</span>
                </div>
                <h4 className="appie-title">AI Tìm Kiếm </h4>
                <p>MonMi sử dụng trí tuệ nhân tạo để tìm kiếm sản phẩm thông qua hình ảnh và chữ </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                <div className="icon">
                  <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/icon/2.png" alt="" />
                    <span>2</span>
                </div>
                <h4 className="appie-title">Nguy Cơ</h4>
                <p>MonMi loại bỏ những tiềm năng gây nguy hiểm đến khách hàng sử dụng dịch vụ.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                <div className="icon">
                  <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/icon/3.png" alt="" />
                    <span>3</span>
                </div>
                <h4 className="appie-title">Bảo Mật</h4>
                <p>MonMi bảo mật thông tin cá nhân của khách hàng lên hàng đầu .</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="800ms">
                <div className="icon">
                  <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/icon/4.png" alt="" />
                    <span>4</span>
                </div>
                <h4 className="appie-title">Hỗ Trợ</h4>
                <p>Đội ngũ hỗ trợ của MonMi luôn trúc trực 24/7 nhằm phục vụ nhu cầu và thắc mắc của khách hàng.</p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="appie-features-area pt-100" id="features">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="appie-features-tabs-btn">
                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><i className="fa fa-cutlery"></i> Đồ Ăn</a>
                  <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false"><i className="fas fa-shopping-cart"></i> Mua Sắm</a>
                  <a className="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false"><i className="fas fa-shipping-fast"></i> Vận Chuyển</a>
                  <a className="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false"><i className="fa fa-grav"></i> Giải Trí</a>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="tab-content" id="v-pills-tabContent">
                <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="appie-features-thumb text-center wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <img src="/photo_2022-04-11_21-11-14.jpg" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="appie-features-content wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">

                        <h3 className="title">Let the Conversation flow</h3>
                        <p>Car boot absolutely bladdered posh burke the wireless mush some dodg.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <img src="/photo_2022-04-11_21-11-14.jpg" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <h3 className="title">Let theConversation flow </h3>
                        <p>Car boot absolutely bladdered posh burke the wireless mush some dodg.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <img src="/photo_2022-04-11_21-11-14.jpg" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <h3 className="title">Let the Conversation flow</h3>
                        <p>Car boot absolutely bladdered posh burke the wireless mush some dodg.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <img src="/photo_2022-04-11_21-11-14.jpg" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <h3 className="title">Let the  Conversation flow </h3>
                        <p>Car boot absolutely bladdered posh burke the wireless mush some dodg.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="features-shape-1">
          <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/shape/shape-6.png" alt="" />
        </div>
        <div className="features-shape-2">
          <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/shape/shape-7.png" alt="" />
        </div>
        <div className="features-shape-3">
          <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/shape/shape-8.png" alt="" />
        </div>
      </section>

      {/*<section className="appie-traffic-area pt-140 pb-180">*/}
      {/*  <div className="container">*/}
      {/*    <div className="row">*/}
      {/*      <div className="col-lg-7">*/}
      {/*        <div className="appie-traffic-title">*/}
      {/*          <span>Traffic</span>*/}
      {/*          <h3 className="title">Stage reporting with  50k queries.</h3>*/}
      {/*          <p>He nicked it tickety boo harry the cras bargy chap mush spiffing spend a penny the full monty burke butty.</p>*/}
      {/*        </div>*/}
      {/*        <div className="row">*/}
      {/*          <div className="col-lg-6 col-md-6">*/}
      {/*            <div className="appie-traffic-service mb-30">*/}
      {/*              <div className="icon">*/}
      {/*                <i className="fal fa-check"></i>*/}
      {/*              </div>*/}
      {/*              <h5 className="title">Carefully designed</h5>*/}
      {/*              <p>Mucker plastered bugger all mate morish are.</p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="col-lg-6 col-md-6">*/}
      {/*            <div className="appie-traffic-service item-2 mb-30">*/}
      {/*              <div className="icon">*/}
      {/*                <i className="fal fa-check"></i>*/}
      {/*              </div>*/}
      {/*              <h5 className="title">Choose a App</h5>*/}
      {/*              <p>Mucker plastered bugger all mate morish are.</p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="col-lg-6 col-md-6">*/}
      {/*            <div className="appie-traffic-service item-3">*/}
      {/*              <div className="icon">*/}
      {/*                <i className="fal fa-check"></i>*/}
      {/*              </div>*/}
      {/*              <h5 className="title">Seamless Sync</h5>*/}
      {/*              <p>Mucker plastered bugger all mate morish are.</p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="col-lg-6 col-md-6">*/}
      {/*            <div className="appie-traffic-service item-4">*/}
      {/*              <div className="icon">*/}
      {/*                <i className="fal fa-check"></i>*/}
      {/*              </div>*/}
      {/*              <h5 className="title">User Interactive</h5>*/}
      {/*              <p>Mucker plastered bugger all mate morish are.</p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="col-lg-12">*/}
      {/*            <div className="traffic-btn mt-50">*/}
      {/*              <a className="main-btn" href="#">Learn More <i className="fa fa-arrow-right"></i></a>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="traffic-thumb ">*/}
      {/*    <img className="wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="200ms" src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/traffic-thumb.png" alt="" />*/}
      {/*  </div>*/}
      {/*</section>*/}


      {/*<section className="appie-testimonial-area pt-100 pb-160" id="testimonial">*/}
      {/*  <div className="container">*/}
      {/*    <div className="row justify-content-center">*/}
      {/*      <div className="col-lg-8">*/}
      {/*        <div className="appie-testimonial-slider">*/}
      {/*          <div className="appie-testimonial-item text-center">*/}
      {/*            <div className="author-info">*/}
      {/*              <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/testimonial-user.png" alt="" />*/}
      {/*                <h5 className="title">Jason Response</h5>*/}
      {/*                <span>April 14, 2022</span>*/}
      {/*            </div>*/}
      {/*            <div className="text">*/}
      {/*              <p>Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.</p>*/}
      {/*              <ul>*/}
      {/*                <li><i className="fas fa-star"></i></li>*/}
      {/*                <li><i className="fas fa-star"></i></li>*/}
      {/*                <li><i className="fas fa-star"></i></li>*/}
      {/*                <li><i className="fas fa-star"></i></li>*/}
      {/*                <li><i className="fas fa-star"></i></li>*/}
      {/*              </ul>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="appie-testimonial-item text-center">*/}
      {/*            <div className="author-info">*/}
      {/*              <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/testimonial-user.png" alt="" />*/}
      {/*                <h5 className="title">Jason Response</h5>*/}
      {/*                <span>April 14, 2022</span>*/}
      {/*            </div>*/}
      {/*            <div className="text">*/}
      {/*              <p>Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.</p>*/}
      {/*              <ul>*/}
      {/*                <li><i className="fas fa-star"></i></li>*/}
      {/*                <li><i className="fas fa-star"></i></li>*/}
      {/*                <li><i className="fas fa-star"></i></li>*/}
      {/*                <li><i className="fas fa-star"></i></li>*/}
      {/*                <li><i className="fas fa-star"></i></li>*/}
      {/*              </ul>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="appie-testimonial-item text-center">*/}
      {/*            <div className="author-info">*/}
      {/*              <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/testimonial-user.png" alt="" />*/}
      {/*                <h5 className="title">Jason Response</h5>*/}
      {/*                <span>April 14, 2022</span>*/}
      {/*            </div>*/}
      {/*            <div className="text">*/}
      {/*              <p>Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.</p>*/}
      {/*              <ul>*/}
      {/*                <li><i className="fas fa-star"></i></li>*/}
      {/*                <li><i className="fas fa-star"></i></li>*/}
      {/*                <li><i className="fas fa-star"></i></li>*/}
      {/*                <li><i className="fas fa-star"></i></li>*/}
      {/*                <li><i className="fas fa-star"></i></li>*/}
      {/*              </ul>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}


      {/*<section className="appie-team-area pt-90 pb-100">*/}
      {/*  <div className="container">*/}
      {/*    <div className="row">*/}
      {/*      <div className="col-lg-12">*/}
      {/*        <div className="appie-section-title text-center">*/}
      {/*          <h3 className="appie-title">Meet our Team Members</h3>*/}
      {/*          <p>Different layouts and styles for team sections.</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="row">*/}
      {/*      <div className="col-lg-3 col-md-6">*/}
      {/*        <div className="appie-team-item mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">*/}
      {/*          <div className="thumb">*/}
      {/*            <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/team-1.jpg" alt="" />*/}
      {/*              <ul>*/}
      {/*                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>*/}
      {/*                <li><a href="#"><i className="fab fa-twitter"></i></a></li>*/}
      {/*                <li><a href="#"><i className="fab fa-pinterest-p"></i></a></li>*/}
      {/*              </ul>*/}
      {/*          </div>*/}
      {/*          <div className="content text-center">*/}
      {/*            <h5 className="title">Benjamin Evalent</h5>*/}
      {/*            <span>CEO-Founder</span>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="col-lg-3 col-md-6">*/}
      {/*        <div className="appie-team-item mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">*/}
      {/*          <div className="thumb">*/}
      {/*            <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/team-2.jpg" alt="" />*/}
      {/*              <ul>*/}
      {/*                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>*/}
      {/*                <li><a href="#"><i className="fab fa-twitter"></i></a></li>*/}
      {/*                <li><a href="#"><i className="fab fa-pinterest-p"></i></a></li>*/}
      {/*              </ul>*/}
      {/*          </div>*/}
      {/*          <div className="content text-center">*/}
      {/*            <h5 className="title">Benjamin Evalent</h5>*/}
      {/*            <span>CEO-Founder</span>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="col-lg-3 col-md-6">*/}
      {/*        <div className="appie-team-item mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">*/}
      {/*          <div className="thumb">*/}
      {/*            <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/team-3.jpg" alt="" />*/}
      {/*              <ul>*/}
      {/*                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>*/}
      {/*                <li><a href="#"><i className="fab fa-twitter"></i></a></li>*/}
      {/*                <li><a href="#"><i className="fab fa-pinterest-p"></i></a></li>*/}
      {/*              </ul>*/}
      {/*          </div>*/}
      {/*          <div className="content text-center">*/}
      {/*            <h5 className="title">Benjamin Evalent</h5>*/}
      {/*            <span>CEO-Founder</span>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="col-lg-3 col-md-6">*/}
      {/*        <div className="appie-team-item mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="800ms">*/}
      {/*          <div className="thumb">*/}
      {/*            <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/team-4.jpg" alt="" />*/}
      {/*              <ul>*/}
      {/*                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>*/}
      {/*                <li><a href="#"><i className="fab fa-twitter"></i></a></li>*/}
      {/*                <li><a href="#"><i className="fab fa-pinterest-p"></i></a></li>*/}
      {/*              </ul>*/}
      {/*          </div>*/}
      {/*          <div className="content text-center">*/}
      {/*            <h5 className="title">Benjamin Evalent</h5>*/}
      {/*            <span>CEO-Founder</span>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="col-lg-12">*/}
      {/*        <div className="team-btn text-center mt-50">*/}
      {/*          <a className="main-btn" href="#"> View all Members <i className="fal fa-arrow-right"></i></a>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}


      {/*<section className="appie-pricing-area pt-90 pb-90">*/}
      {/*  <div className="container">*/}
      {/*    <div className="row">*/}
      {/*      <div className="col-lg-12">*/}
      {/*        <div className="appie-section-title text-center">*/}
      {/*          <h3 className="appie-title">Choose your plan</h3>*/}
      {/*          <p>Different layouts and styles for team sections.</p>*/}
      {/*        </div>*/}
      {/*        <ul className="list-inline text-center switch-toggler-list" role="tablist" id="switch-toggle-tab">*/}
      {/*          <li className="month active"><a href="#">Monthly</a></li>*/}
      {/*          <li>*/}
      {/*            <label className="switch on">*/}
      {/*              <span className="slider round"></span>*/}
      {/*            </label>*/}
      {/*          </li>*/}
      {/*          <li className="year"><a href="#">Annualy</a></li>*/}
      {/*        </ul>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="tabed-content">*/}
      {/*      <div id="month">*/}
      {/*        <div className="row justify-content-center">*/}
      {/*          <div className="col-lg-4 col-md-6 wow animated fadeInLeft">*/}
      {/*            <div className="pricing-one__single">*/}
      {/*              <div className="pricig-heading">*/}
      {/*                <h6>Fresh</h6>*/}
      {/*                <div className="price-range"><sup>$</sup> <span>04</span><p>/month</p></div>*/}
      {/*                <p>Get your 14 day free trial</p>*/}
      {/*              </div>*/}
      {/*              <div className="pricig-body">*/}
      {/*                <ul>*/}
      {/*                  <li><i className="fal fa-check"></i> 60-day chat history</li>*/}
      {/*                  <li><i className="fal fa-check"></i> 15 GB cloud storage</li>*/}
      {/*                </ul>*/}
      {/*                <div className="pricing-btn mt-35">*/}
      {/*                  <a className="main-btn" href="#">Choose plan</a>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="col-lg-4 col-md-6 wow animated fadeInUp">*/}
      {/*            <div className="pricing-one__single center">*/}
      {/*              <div className="pricig-heading">*/}
      {/*                <h6>Sweet</h6>*/}
      {/*                <div className="price-range"><sup>$</sup> <span>16</span><p>/month</p></div>*/}
      {/*                <p>Billed $276 per website annually.</p>*/}
      {/*              </div>*/}
      {/*              <div className="pricig-body">*/}
      {/*                <ul>*/}
      {/*                  <li><i className="fal fa-check"></i> 60-day chat history</li>*/}
      {/*                  <li><i className="fal fa-check"></i> 50 GB cloud storage</li>*/}
      {/*                  <li><i className="fal fa-check"></i> 24/7 Support</li>*/}
      {/*                </ul>*/}
      {/*                <div className="pricing-btn mt-35">*/}
      {/*                  <a className="main-btn" href="#">Choose plan</a>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*              <div className="pricing-rebon">*/}
      {/*                <span>Most Popular</span>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="col-lg-4 col-md-6 wow animated fadeInRight">*/}
      {/*            <div className="pricing-one__single">*/}
      {/*              <div className="pricig-heading">*/}
      {/*                <h6>Juicy</h6>*/}
      {/*                <div className="price-range"><sup>$</sup> <span>27</span><p>/month</p></div>*/}
      {/*                <p>Billed $276 per website annually.</p>*/}
      {/*              </div>*/}
      {/*              <div className="pricig-body">*/}
      {/*                <ul>*/}
      {/*                  <li><i className="fal fa-check"></i> 60-day chat history</li>*/}
      {/*                  <li><i className="fal fa-check"></i> Data security</li>*/}
      {/*                  <li><i className="fal fa-check"></i> 100 GB cloud storage</li>*/}
      {/*                  <li><i className="fal fa-check"></i> 24/7 Support</li>*/}
      {/*                </ul>*/}
      {/*                <div className="pricing-btn mt-35">*/}
      {/*                  <a className="main-btn" href="#">Choose plan</a>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div id="year">*/}
      {/*        <div className="row justify-content-center">*/}
      {/*          <div className="col-lg-4 col-md-6 wow animated fadeInLeft">*/}
      {/*            <div className="pricing-one__single">*/}
      {/*              <div className="pricig-heading">*/}
      {/*                <h6>Fresh</h6>*/}
      {/*                <div className="price-range"><sup>$</sup> <span>32</span><p>/Yearly</p></div>*/}
      {/*                <p>Get your 14 day free trial</p>*/}
      {/*              </div>*/}
      {/*              <div className="pricig-body">*/}
      {/*                <ul>*/}
      {/*                  <li><i className="fal fa-check"></i> 60-day chat history</li>*/}
      {/*                  <li><i className="fal fa-check"></i> 15 GB cloud storage</li>*/}
      {/*                </ul>*/}
      {/*                <div className="pricing-btn mt-35">*/}
      {/*                  <a className="main-btn" href="#">Choose plan</a>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="col-lg-4 col-md-6 wow animated fadeInUp">*/}
      {/*            <div className="pricing-one__single center">*/}
      {/*              <div className="pricig-heading">*/}
      {/*                <h6>Sweet</h6>*/}
      {/*                <div className="price-range"><sup>$</sup> <span>116</span><p>/Yearly</p></div>*/}
      {/*                <p>Billed $276 per website annually.</p>*/}
      {/*              </div>*/}
      {/*              <div className="pricig-body">*/}
      {/*                <ul>*/}
      {/*                  <li><i className="fal fa-check"></i> 60-day chat history</li>*/}
      {/*                  <li><i className="fal fa-check"></i> 50 GB cloud storage</li>*/}
      {/*                  <li><i className="fal fa-check"></i> 24/7 Support</li>*/}
      {/*                </ul>*/}
      {/*                <div className="pricing-btn mt-35">*/}
      {/*                  <a className="main-btn" href="#">Choose plan</a>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*              <div className="pricing-rebon">*/}
      {/*                <span>Most Popular</span>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="col-lg-4 col-md-6 wow animated fadeInRight">*/}
      {/*            <div className="pricing-one__single">*/}
      {/*              <div className="pricig-heading">*/}
      {/*                <h6>Juicy</h6>*/}
      {/*                <div className="price-range"><sup>$</sup> <span>227</span><p>/Yearly</p></div>*/}
      {/*                <p>Billed $276 per website annually.</p>*/}
      {/*              </div>*/}
      {/*              <div className="pricig-body">*/}
      {/*                <ul>*/}
      {/*                  <li><i className="fal fa-check"></i> 60-day chat history</li>*/}
      {/*                  <li><i className="fal fa-check"></i> Data security</li>*/}
      {/*                  <li><i className="fal fa-check"></i> 100 GB cloud storage</li>*/}
      {/*                  <li><i className="fal fa-check"></i> 24/7 Support</li>*/}
      {/*                </ul>*/}
      {/*                <div className="pricing-btn mt-35">*/}
      {/*                  <a className="main-btn" href="#">Choose plan</a>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}


      {/*<section className="appie-faq-area pb-95">*/}
      {/*  <div className="container">*/}
      {/*    <div className="row">*/}
      {/*      <div className="col-lg-12">*/}
      {/*        <div className="appie-section-title text-center">*/}
      {/*          <h3 className="appie-title">Frequently asked questions</h3>*/}
      {/*          <p>Different layouts and styles for team sections.</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="row">*/}
      {/*      <div className="col-lg-6">*/}
      {/*        <div className="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms">*/}
      {/*          <div className="accrodion-grp wow fadeIn" data-wow-duration="1500ms" data-grp-name="faq-accrodion">*/}
      {/*            <div className="accrodion active ">*/}
      {/*              <div className="accrodion-inner">*/}
      {/*                <div className="accrodion-title">*/}
      {/*                  <h4>Where do I usually find FAQs in a page?</h4>*/}
      {/*                </div>*/}
      {/*                <div className="accrodion-content">*/}
      {/*                  <div className="inner">*/}
      {/*                    <p>Naff Oxford vagabond in my flat chinwag blatant grub tomfoolery that I bits and bobs up the duff cras boot bevvy no biggie.</p>*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*            <div className="accrodion  ">*/}
      {/*              <div className="accrodion-inner">*/}
      {/*                <div className="accrodion-title">*/}
      {/*                  <h4>Where do I usually find FAQs in a page?</h4>*/}
      {/*                </div>*/}
      {/*                <div className="accrodion-content">*/}
      {/*                  <div className="inner">*/}
      {/*                    <p>Naff Oxford vagabond in my flat chinwag blatant grub tomfoolery that I bits and bobs up the duff cras boot bevvy no biggie.</p>*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="col-lg-6">*/}
      {/*        <div className="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms">*/}
      {/*          <div className="accrodion-grp wow fadeIn" data-wow-duration="1500ms" data-grp-name="faq-accrodion">*/}
      {/*            <div className="accrodion ">*/}
      {/*              <div className="accrodion-inner">*/}
      {/*                <div className="accrodion-title">*/}
      {/*                  <h4>Where do I usually find FAQs in a page?</h4>*/}
      {/*                </div>*/}
      {/*                <div className="accrodion-content">*/}
      {/*                  <div className="inner">*/}
      {/*                    <p>Naff Oxford vagabond in my flat chinwag blatant grub tomfoolery that I bits and bobs up the duff cras boot bevvy no biggie.</p>*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*            <div className="accrodion  ">*/}
      {/*              <div className="accrodion-inner">*/}
      {/*                <div className="accrodion-title">*/}
      {/*                  <h4>Where do I usually find FAQs in a page?</h4>*/}
      {/*                </div>*/}
      {/*                <div className="accrodion-content">*/}
      {/*                  <div className="inner">*/}
      {/*                    <p>Naff Oxford vagabond in my flat chinwag blatant grub tomfoolery that I bits and bobs up the duff cras boot bevvy no biggie.</p>*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="col-lg-12">*/}
      {/*        <div className="faq-text text-center pt-40">*/}
      {/*          <p>Can't find an answer? <a href="mailto:demo@gmail.com">Email us</a></p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}

      {/*<section className="appie-blog-area pt-90 pb-95">*/}
      {/*  <div className="container">*/}
      {/*    <div className="row">*/}
      {/*      <div className="col-lg-12">*/}
      {/*        <div className="appie-section-title text-center">*/}
      {/*          <h3 className="appie-title">Latest blog posts</h3>*/}
      {/*          <p>Different layouts and styles for team sections.</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="row">*/}
      {/*      <div className="col-lg-4 col-md-6">*/}
      {/*        <div className="appie-blog-item mt-30 wow animated fadeInUp" data-wow-duration="3000ms" data-wow-delay="200ms">*/}
      {/*          <div className="thumb">*/}
      {/*            <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/blog-1.jpg" alt="" />*/}
      {/*          </div>*/}
      {/*          <div className="content">*/}
      {/*            <div className="blog-meta">*/}
      {/*              <ul>*/}
      {/*                <li>March 10, 2022</li>*/}
      {/*                <li><a href="#">Saas & App</a></li>*/}
      {/*              </ul>*/}
      {/*            </div>*/}
      {/*            <h3 className="title"><a href="#">Introducing New Apps Design for our iOS App</a></h3>*/}
      {/*            <a href="#">Learn More <i className="fal fa-arrow-right"></i></a>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="col-lg-4 col-md-6">*/}
      {/*        <div className="appie-blog-item mt-30 wow animated fadeInUp" data-wow-duration="3000ms" data-wow-delay="400ms">*/}
      {/*          <div className="thumb">*/}
      {/*            <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/blog-2.jpg" alt="" />*/}
      {/*          </div>*/}
      {/*          <div className="content">*/}
      {/*            <div className="blog-meta">*/}
      {/*              <ul>*/}
      {/*                <li>March 10, 2022</li>*/}
      {/*                <li><a href="#">Saas & App</a></li>*/}
      {/*              </ul>*/}
      {/*            </div>*/}
      {/*            <h3 className="title"><a href="#">How to bring Dolce to your company</a></h3>*/}
      {/*            <a href="#">Learn More <i className="fal fa-arrow-right"></i></a>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="col-lg-4 col-md-6">*/}
      {/*        <div className="appie-blog-item mt-30 wow animated fadeInUp" data-wow-duration="3000ms" data-wow-delay="600ms">*/}
      {/*          <div className="thumb">*/}
      {/*            <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/blog-3.jpg" alt="" />*/}
      {/*          </div>*/}
      {/*          <div className="content">*/}
      {/*            <div className="blog-meta">*/}
      {/*              <ul>*/}
      {/*                <li>March 10, 2022</li>*/}
      {/*                <li><a href="#">Saas & App</a></li>*/}
      {/*              </ul>*/}
      {/*            </div>*/}
      {/*            <h3 className="title"><a href="#">17 Effective Ways to Monetize Mobile Apps</a></h3>*/}
      {/*            <a href="#">Learn More <i className="fal fa-arrow-right"></i></a>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}

      <section className="appie-project-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-project-box wow animated slideInUp" data-wow-duration="1000ms" data-wow-delay="0ms">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="appie-project-content">
                      <h3 className="title">Start your project with appie.</h3>
                      <p>We will email you only about this product.</p>
                      <form action="#">
                        <div className="input-box mt-30">
                          <input type="text" placeholder="Your email" />
                            <button>Subscribe</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="appie-project-thumb">
                  <img src="https://quomodosoft.com/html/appie/assets/appie-demo/assets/images/project-thumb.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="appie-footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-about-widget">
                <div className="logo">
                  <a href="#"><img style={{width:'50%'}} src="/moni.png" alt="" /></a>
                </div>
                <p> Mon Mi sẽ là điểm đến cho mọi nhu cầu của bạn về mua sắm, đồ ăn và giải trí.</p>
                <div className="social mt-30">
                  <ul>
                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="#"><i className="fab fa-pinterest-p"></i></a></li>
                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">Company</h4>
                <ul>
                  <li><a href="#">About Us</a></li>
                  <li><a href="#">Service</a></li>
                  <li><a href="#">Case Studies</a></li>
                  <li><a href="#">Blog</a></li>
                  <li><a href="#">Contact</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">Support</h4>
                <ul>
                  <li><a href="#">Community</a></li>
                  <li><a href="#">Resources</a></li>
                  <li><a href="#">Faqs</a></li>
                  <li><a href="#">Privacy Policy</a></li>
                  <li><a href="#">Careers</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget-info">
                <h4 className="title">Get In Touch</h4>
                <ul>
                  <li><a href=""><i className="fa fa-envelope"></i> support@monmi.vn</a></li>
                  <li><a href=""><i className="fa fa-phone"></i> +(84)  249 998 2323</a></li>
                  <li><a href=""><i className="fa fa-map-marker-alt"></i> 251 Trần Quang Khải, Phường Tân Định, Quận 1</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                <div className="apps-download-btn">
                  <ul>
                    <li><a href="#"><i className="fab fa-apple"></i> Download for iOS</a></li>
                    <li><a className="item-2" href="#"><i className="fab fa-google-play"></i> Download for Android</a></li>
                  </ul>
                </div>
                <div className="copyright-text">
                  <p>Copyright © 2022 Mon Mi. All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="back-to-top">
        <a href="#"><i className="fa fa-arrow-up"></i></a>
      </div>



    </div>
  );
}

export default App;
